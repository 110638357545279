.no-result {
    text-align: center;
    padding: 2rem;
}

.in-progress {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.results-container {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.results-data-container {
    overflow-y: auto;

    flex: 1;

    display: flex;
    flex-direction: row;
}

.results-data-container h4 {
    text-align: center;
}

.results-layers-tree-container {
    width: 16rem;

    display: flex;
    flex-direction: column;

    background-color: #fafafa;
}

.results-layers-tree {
    overflow-y: auto;
}

.results-selected-feature-attributes-containers {
    flex: 1;

    display: flex;
    flex-direction: column;
}

.results-buttons-container {
    padding: 0.5rem 1rem;

    /* copied from Mui table border */
    border-top: 1px solid rgba(224, 224, 224, 1);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.results-button {
    height: 2rem;
}
