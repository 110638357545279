.esri-view:focus .esri-view-surface--inset-outline:focus::after {
  outline: auto 0;
}

.esri-component {
  box-shadow: none !important;
}

.esri-widget {
  outline: auto 0;
}

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 0em;
  font-family: 'Avenir Next', 'Helvetica Neue', sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 0;
}

button:focus, select:focus, span:focus, div:focus, input:focus {
  outline: none;
}

.MuiButtonBase-root {
  margin: 0.25rem !important;
}

.MuiFormControl-root, .MuiTextField-root {
  margin-top: 10px !important;
}

.MuiButton-label, .MuiListItem-root, .MuiLSelect-root, .MuiInputBase-input, .MuiOutlinedInput-input, .MuiInputBase-inputMarginDense, .MuiOutlinedInput-inputMarginDense {
  font-size: 12px !important;
}

.MuiTreeItem-label, .MuiTableCell-body {
  font-size: 12px !important;
}

/* .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-marginDense, .MuiInputLabel-outlined  */

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w, .react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n, .react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.draggable {
  background: 'pink';
  cursor: grab;
}